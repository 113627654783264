import React, { useContext } from "react";
import * as styles from "./LatestNew.module.css";
import Footer from "../../Components/Footer/Footer";
import Header,{languageContext} from "../../Components/Header/Header";
import PressCard from "../../Components/PressCard/PressCard";
import { Link, graphql } from "gatsby";
import bannerImg from "../../assets/images/personeBanner.png"
import TextWithButton from "../../Components/TextWithButton/TextWithButton";

const Index = ({data}) => {
  const llanguage = useContext(languageContext);
  const { language } = llanguage;
  const localizations = data?.allStrapiLatestNews?.edges.map((itm) => itm.node?.localizations) || {};
 // const title = language === "it" ? localizations?.data[0]?.attributes : data?.allStrapiLatestNews?.edges[0]?.node || {}; 
  //const {description,button} = language === "it" ? localizations?.data?.attributes?.Blocks[5]:data?.allStrapiLatestNews?.edges[0]?.node?.Blocks[5]; 
   //const newsdata = language === "it" ? localizations.map((itm) => itm) : data?.allStrapiLatestNews?.edges; 
   const newsdata = language === "it" ? data?.allStrapiLatestNews?.edges.map((itm) => itm.node?.localizations) : data?.allStrapiLatestNews?.edges;
   const itSlugs = data?.allStrapiLatestNews?.edges.flatMap((item,index)=>{ 
    const sl = item.node.slug;
    return sl
   })

  //  const slug = data?.allStrapiLatestNews?.edges.map((item,index)=>{
  //    const sl = item.node.slug;
  //    console.log('sl', sl)
  //    const slobj = {sl}
  //    newsdata.push(slobj)
  //    console.log('item', item)
  //   });
  
    return (
        <>
        <div className="pageWrapper">
          <div className={styles.pressMainWrap}>
            <Header />
            <div className={styles.pressSection}>
              <div className={styles.headingName}>News</div>
              <div className={styles.pressMain}>
                {/* <VisitContent press={true} /> */}
                <div className={styles.pressGrid}>
                    {newsdata && newsdata?.map((item,index) => {
                       const slug = language ==="it" ? itSlugs[index] : item.node.slug || (item?.node?.localizations && item.node.localizations.data[0]?.attributes.slug);
                    
                      return(
                        <Link to={`/LatestNews/${slug}`}>
                      <PressCard
                        img={item?.node?.image?.url || item?.data?.[0]?.attributes?.image?.data?.attributes?.url} 
                        pressDescription={item?.node?.title || item?.data?.[0]?.attributes?.title}
                        pressHeading={item?.node?.reporter || item?.data?.[0]?.attributes?.reporter}
                      />
                    </Link>
                      )
                    })}  
                </div>
              </div> 
              {/* <TextWithButton  description={PressKitDescription} button={button}/>   */}
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
};

export default Index;



export const query=graphql`
query MyQuery {
  allStrapiLatestNews (sort: {fields: localizations___data___attributes___updatedAt, order: DESC}) {
    edges {
      node { 
        slug
        reporter
        title
        image {
          url
        }
       
        localizations {
          data {
            attributes {
              title
              slug
              reporter
              image {
                data {
                  attributes {
                    url
                  }
                }
              }
            
            }
          }
        }
      }
       
    }
  }


 
  allStrapiPress {
    edges {
      node {
        DownloadButtonLink {
          href
          label
        }
        PageTitle
        PressKitDescription
        MetaKeyword
        MetaDescription
        localizations {
          data {
            attributes {
              PageTitle
              PressKitDescription
              MetaKeyword
              MetaDescription
            }
          }
        }
      }
    }
  }
}

`
import React from "react";
// import Header from '../../Components/Header/Header';
import * as styles from "./PressCard.module.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css';

const PressCard = (props) => {
  //console.log('props', props)
  const { pressDescription, pressHeading, img } = props;
  return (
    <div className={styles.pressCard}>
      <img src={img} alt="icon"PressKitDescription />
      <div>
      <AnimationOnScroll  animateIn='animate__animated animate__fadeInUp'>
        <h6 className="animate__animated animate__fadeInUp">{pressDescription}</h6>
        </AnimationOnScroll>
        <AnimationOnScroll  animateIn='animate__animated animate__fadeInUp'>
        <p className="animate__animated animate__fadeInUp">{pressHeading}</p>
        </AnimationOnScroll>
      </div>
    </div>
  );
};

export default PressCard;
